import React from 'react';

import PageWrapper from '../components/PageWrapper';
import Hero from '../sections/refer/Hero';
import ContentOne from '../sections/refer/ContentOne';
import ContentTwo from '../sections/refer/ContentTwo';
import ContentThree from '../sections/refer/ContentThree';
import Testimonial from '../sections/refer/Testimonial';

const Refer = ({ location }) => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName:
            'site-header--menu-right dark-mode-texts dropdown-left',
          headerButton: (
            <>
              <button className="btn btn btn-white btn-medium rounded-5 font-size-3">
                Create an Account
              </button>
            </>
          ),
          footerStyle: 'style2',
        }}
      >
        <Hero
          referral={{
            name: location.search.split('?referral=')[1],
          }}
          className="bg-blue-1 position-relative pt-20 pt-sm-24 pt-lg-34 pb-9 pb-lg-32"
        />
        <ContentOne className="pt-13 pt-lg-28 pb-13 pb-lg-26" />
        <ContentTwo className="bg-default-4 pt-15 pt-lg-30 pb-15 pb-lg-26" />
        <ContentThree className="bg-dark-cloud pt-30 pt-lg-28 pb-15 pb-lg-30" />
        <Testimonial />
      </PageWrapper>
    </>
  );
};
export default Refer;
